body {
    font-family: 'Lato';
    background-color: #f5f5f5;  
}
* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}
.nav_logo {
    height: 120px;
    width: auto;
}
::-webkit-scrollbar {
    width: 0px;
}

.right-arrow,
.left-arrow {
    cursor: pointer;
    background: transparent;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FF2625;
    font-size: 25px;
    border-radius: 4px;
    position: absolute;
    bottom: -20px;
    transform: scale(1, 1);
    transition: 0.3s all ease-in-out;
}
.left-arrow {
    left: 60%;
}
.right-arrow {
    right: 60%;
}

    .right-arrow:hover,
    .left-arrow:hover {
        transform: scale(1.1, 1.1);
    }

.react-horizontal-scrolling-menu--wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
/* ajout */
.react-horizontal-scrolling-menu--scroll-container {
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    margin-top:20px;
}
/* ajout */

.detail-image {
    width: 100%;
    height: auto;
}

.hero-banner-img {
    position: absolute;
    right: 15%;
    top: -100px;
    width: 700px;
    height: auto;
    margin-top: -120px;
    margin-right:0px;
    opacity:0.05;
}

.exercise-card {
    width: 300px;
    height: 420px;
    background: #fff;
    border-left: 5px solid #333333;

    border-radius: 4px;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    transform: scale(1, 1);
    transition: 0.3s all ease-in-out;
}

    .exercise-card img {
        height: 320px;
        border-bottom: 3px dotted #333333;
    }

.bodyPart-card {
    transform: scale(1, 1);
    transition: 0.3s all ease-in-out;
}

    .exercise-card:hover,
    .bodyPart-card:hover {
        transform: scale(0.99, 0.99);
    }

.search-btn:hover {
    color: #f5f5f5 !important;
    background-color: #333333 !important;
    /* border: 1px solid #FF2625 !important; */
}

.exercise-video {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 320px;
    height: 180px;
    text-decoration: none;
}

@media screen and (max-width:1200px) {
    .nav_logo {
        height: 80px;
    }
    .detail-image {
        width: 300px;
        height: 300px;
    }


    .react-horizontal-scrolling-menu--scroll-container {
        width: 500px;
    }

    
    .hero-banner-img {        
        top: 0px;
        width: 400px;
        height: auto;
        margin-top: 0px;
    }
    

    .exercise-card {
        width: 320px;
    }

    .exercise-video {
        width: 320px;
        height: 300px;
    }
}
@media screen and (max-width:900px) {
    .nav_logo {
        height: 40px;
    }
    .hero-banner-img {
        display: none;
    }
}

@media screen and (max-width:400px) {
    .exercise-card {
        width: 280px;
    }
    
}


